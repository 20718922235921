export const helpCenters = [
  {
    pk: 1,
    county: 'OLT',
    locality: 'Slatina',
    address: 'Bd. Alexandru Ioan Cuza, nr. 7',
    lat: 44.4283747,
    lng: 24.3686168,
    email: 'office@primariaslatina.ro',
    phoneNumber: '0722219293',
  },
  {
    pk: 2,
    county: 'OLT',
    locality: 'Caracal',
    address: 'Str. Victoriei, nr.10',
    lat: 44.112425,
    lng: 24.3453432,
    email: 'office@primariacaracal.ro',
    phoneNumber: '0747115042',
  },
  {
    pk: 3,
    county: 'OLT',
    locality: 'Corabia',
    address: 'Str. Carpați, nr.6',
    lat: 44.1158898,
    lng: 24.3515587,
    email: 'primariacorabia@yahoo.com',
    phoneNumber: '0770680230',
  },
  {
    pk: 4,
    county: 'VRANCEA',
    locality: 'Focșani',
    address: 'Str. Dumitrie Cantemir, nr. 5',
    lat: 45.697134944230804,
    lng: 27.18613329562449,
    email: 'vrancea@crucearosie.ro',
    phoneNumber: '0741993954',
  },
  {
    pk: 7,
    county: 'DÂMBOVIȚA',
    locality: 'Târgoviște',
    address: 'Str. Alexandru Ioan Cuza, nr. 1',
    lat: 44.9301943,
    lng: 25.4599626,
    email: 'dambovita@crucearosie.ro',
    phoneNumber: '',
  },
  {
    pk: 8,
    county: 'BUCUREȘTI',
    locality: 'Sector 2',
    address: 'Bd. Basarabia, nr. 37-39',
    building: 'Arena Națională - Clădirea Academiei de Tenis',
    lat: 44.4333757,
    lng: 26.148619,
    email: '',
    phoneNumber: '0219524',
  },
  {
    pk: 9,
    county: 'BUCUREȘTI',
    locality: 'Sector 5',
    address: 'Bd. Regina Elisabeta, nr. 75',
    building: 'Centrul Bebe de București',
    lat: 44.4345883,
    lng: 26.0886983,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 10,
    county: 'BUCUREȘTI',
    locality: 'Sector 1',
    address: 'Calea Plevnei, nr. 98',
    building: 'Centrul de Economie Circulară',
    lat: 44.438961,
    lng: 26.0759754,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 11,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Școala Gimnazială nr. 13',
    lat: 44.5118141,
    lng: 26.0161855,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 12,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Școala Gimnazială I.H. Rădulescu',
    lat: 44.4552333,
    lng: 26.0814614,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 13,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Școala Gimnazială Alexandru Costescu',
    lat: 44.4760524,
    lng: 26.0366424,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 14,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Liceul Teoretic Nicolae Iorga',
    lat: 44.4649033,
    lng: 26.0680998,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 15,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Colegiul Tehnic Media',
    lat: 44.4854178,
    lng: 26.0588351,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 16,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Colegiul Național Ion Neculce',
    lat: 44.4548747,
    lng: 26.0730656,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 17,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Colegiul Tehnic Dinicu Golescu',
    lat: 44.4528984,
    lng: 26.0578511,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 18,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Școala Specială pentru Deficienți de vedere',
    lat: 44.436169,
    lng: 26.122974,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 19,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Grădinița nr. 189',
    lat: 44.4379644,
    lng: 26.1755405,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 20,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Grădinița Albinuța',
    lat: 44.4633875,
    lng: 26.1024153,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 21,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Grădinița nr. 280',
    lat: 44.4438784,
    lng: 26.1606279,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 22,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Liceul Tehnologic Special Regina Elizabeta',
    lat: 44.4438822,
    lng: 26.1606279,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 23,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Colegiul Național Școala Centrală',
    lat: 44.4431346,
    lng: 26.1033397,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 24,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Școala Gimnazială nr. 85',
    lat: 44.4388907,
    lng: 26.1320769,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 25,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Școala Gimnazială de Arte nr. 5',
    lat: 44.4306071,
    lng: 26.1464765,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 26,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Școala Gimnazială nr. 80',
    lat: 44.4209292,
    lng: 26.1336581,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 27,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Grădinița nr. 69',
    lat: 44.4305168,
    lng: 26.1574275,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 29,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Grădinița nr. 191',
    lat: 44.4238793,
    lng: 26.1667247,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 30,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Grădinița nr. 239',
    lat: 44.4263339,
    lng: 26.1444198,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 31,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Școala Gimnazială Emil Racoviță',
    lat: 44.3819615,
    lng: 26.1245724,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 33,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Colegiul Tehnic Energetic',
    lat: 44.3877045,
    lng: 26.0895346,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 34,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Colegiul Național Grigore Moisil',
    lat: 44.4266123,
    lng: 26.0390004,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 35,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Școala Gimnazială nr. 206',
    lat: 44.4228673,
    lng: 26.0140806,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 36,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Grădinița Hillary Clinton',
    lat: 44.425457,
    lng: 26.0377535,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 37,
    county: 'BUCUREȘTI',
    locality: '',
    address: '',
    building: 'Grădinița nr. 41',
    lat: 44.417514,
    lng: 26.0434152,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 38,
    county: 'DOLJ',
    locality: 'Craiova',
    address: 'Bd. Dacia, nr. 67B',
    lat: 44.3361099,
    lng: 23.7923989,
    email: 'canca57@yahoo.ro',
  },
  {
    pk: 39,
    county: 'DOLJ',
    locality: 'Craiova',
    address: 'Str. General Dragalina, nr. 17',
    lat: 44.3118747,
    lng: 23.8035039,
    email: 'dolj@crucearosie.ro',
    phoneNumber: '0744768225',
  },
  {
    pk: 47,
    county: 'VASLUI',
    locality: 'Vaslui',
    address: 'Str. Ștefan cel Mare, nr. 22',
    building: 'Centrul Interex',
    lat: 46.6293528647501,
    lng: 27.73644686949328,
    email: 'vaslui@crucearosie.ro',
    phoneNumber: '',
  },
  {
    pk: 48,
    county: 'VASLUI',
    locality: 'Bârlad',
    address: 'Bd. Primăverii, nr.14',
    lat: 46.2196393,
    lng: 27.6693657,
    email: 'scoalavasileparvan@gmail.ro',
    phoneNumber: '0235410470',
  },
  {
    pk: 49,
    county: 'VASLUI',
    locality: 'Vaslui',
    address: 'Str. Vasile Lupu, nr. 416-417',
    lat: 46.6539289,
    lng: 27.7364444,
    email: 'sc10ro@yahoo.com',
    phoneNumber: '0335410993',
  },
  {
    pk: 50,
    county: 'VASLUI',
    locality: 'Vaslui',
    address: 'Str. Alecu Donici, nr. 2',
    lat: 46.6408232,
    lng: 27.7260573,
    email: 'isjvaslui@isj.edu.ro',
    phoneNumber: '0235311928',
  },
  {
    pk: 53,
    county: 'HUNEDOARA',
    locality: 'Deva',
    address: 'Bd. 1 Decembrie 1918',
    building: 'Casa de Cultură',
    lat: 45.8784563,
    lng: 22.9017198,
    email: '',
    phoneNumber: '0773832096',
  },
  {
    pk: 58,
    county: 'HARGHITA',
    locality: 'Miercurea Ciuc',
    address: 'Piața Libertății, nr. 5',
    lat: 46.3611951,
    lng: 25.8014315,
    email: 'patrimoniu@judetulharghita.ro',
    phoneNumber: '0744600569',
  },
  {
    pk: 59,
    county: 'HARGHITA',
    locality: 'Miercurea Ciuc',
    address: 'Bd. Timișoarei, nr.3',
    lat: 46.3574482,
    lng: 25.8031616,
    email: 'contact@jandarmeriaharghita.ro',
    phoneNumber: '0749089536',
  },
  {
    pk: 61,
    county: 'HARGHITA',
    locality: 'Miercurea Ciuc',
    address: 'Str. Petőfi Sándor, nr.3',
    lat: 46.3612792,
    lng: 25.8058121,
    email: 'crucearosie.harghita@gmail.com',
    phoneNumber: '',
  },
  {
    pk: 66,
    county: 'CLUJ',
    locality: 'Cluj-Napoca',
    address: 'Str. George Coșbuc, nr.11',
    lat: 46.7664303,
    lng: 23.5756524,
    email: 'cluj@crucearosie.ro',
    phoneNumber: '0264592447',
  },
  {
    pk: 78,
    county: 'ARGEȘ',
    locality: 'Mioveni',
    address: 'Str. Tudor Mușatescu, bl. V2B',
    lat: 44.95625,
    lng: 24.93844,
    email: 'ionescumarilena@yahoo.com',
    phoneNumber: '0733100998',
  },
  {
    pk: 96,
    county: 'SIBIU',
    locality: 'Șura Mică',
    address: 'Parc Industrial P.32',
    lat: 45.83783257245461,
    lng: 24.06927467385407,
    email: '',
    phoneNumber: '0741118975',
  },
  {
    pk: 97,
    county: 'SIBIU',
    locality: 'Avrig',
    address: 'Str. Gheorghe Lazăr, nr. 10',
    lat: 45.7276651,
    lng: 24.3742097,
    email: 'lidia.badila@primaria-avrig.ro',
    phoneNumber: '0748871850',
  },
  {
    pk: 101,
    county: 'VALCEA',
    locality: 'Râmnicul Vâlcea',
    address: 'Bd. Nicolae Bălcescu nr.24',
    lat: 45.10808643783796,
    lng: 24.3709023982838,
    email: 'valcea@crucearosie.ro',
    phoneNumber: '0721774197',
  },
  {
    pk: 106,
    county: 'MEHEDINȚI',
    locality: 'Comuna Punghina',
    address: 'Sat Punghina',
    building: 'Primărie Comuna Punghina',
    lat: 44.2838019,
    lng: 22.9344789,
    email: 'clpunghina@yahoo.com',
    phoneNumber: '0742833552',
  },
  {
    pk: 107,
    county: 'MEHEDINȚI',
    locality: 'Comuna Cujmir',
    address: 'Sat Cujmir',
    building: 'Căminul cultural',
    lat: 44.2050323,
    lng: 22.925391,
    email: 'primariacujmir@pejmh.ro',
    phoneNumber: '0748498508',
  },
  {
    pk: 108,
    county: 'MEHEDINȚI',
    locality: 'Comuna Bâlvănești',
    address: 'Sat Bâlvănești',
    building: 'Primărie Comuna Bâlvănești',
    lat: 44.7971509,
    lng: 22.677998,
    email: 'clbalvanesti@gmail.com',
    phoneNumber: '0761789057',
  },
  {
    pk: 109,
    county: 'MEHEDINȚI',
    locality: 'Comuna Dârvari',
    address: 'Sat Dârvari',
    building: 'Primărie Comuna Dârvari',
    lat: 44.2003621,
    lng: 22.9688053,
    email: 'primariadirvari@pejmh.ro',
    phoneNumber: '0727588276',
  },
  {
    pk: 110,
    county: 'MEHEDINȚI',
    locality: 'Comuna Dumbrava',
    address: 'Sat Dumbrava de Jos',
    building: 'Primărie Comuna Dumbrava',
    lat: 44.510417,
    lng: 23.1530721,
    email: 'primar@cldumbrava.ro',
    phoneNumber: '0252342661',
  },
  {
    pk: 116,
    county: 'BACĂU',
    locality: 'Bacău',
    address: 'Str. Calea Mărășești, nr. 5',
    building: 'Primărie Bacău',
    lat: 46.5619771,
    lng: 26.9101094,
    email: '',
    phoneNumber: '0234581853',
  },
  {
    pk: 117,
    county: 'BACĂU',
    locality: 'Bacău',
    address: 'Str. Henri Coandă, nr. 2',
    building: 'Direcția de Evidență a Populației',
    lat: 46.5350306,
    lng: 26.9120987,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 120,
    county: 'BACĂU',
    locality: 'Moinești',
    address: 'Str. Vasile Alecsandri, nr. 14',
    building: 'Primărie Moinești',
    lat: 46.474475,
    lng: 26.4859138,
    email: 'office@moinesti.ro',
    phoneNumber: '0734363680',
  },
  {
    pk: 123,
    county: 'BACĂU',
    locality: 'Buhuși',
    address: 'Str. Tineretului, nr. 1',
    building: 'Casa de Cultură',
    lat: 46.670317,
    lng: 26.5979403,
    email: 'primariabuhusi@yahoo.com',
    phoneNumber: '0741671325',
  },
  {
    pk: 125,
    county: 'MARAMUREȘ',
    locality: 'Baia Mare',
    address: 'Str. Hortensiei, nr. 1',
    lat: 47.6495605,
    lng: 23.5730867,
    email: ['ioana.teodorescu@prefecturamaramures.ro', 'ioana.teodorescu25@yahoo.ro'],
    phoneNumber: '0755793869',
  },
  {
    pk: 133,
    county: 'PRAHOVA',
    locality: 'Ploiesti',
    address: 'Bulevardul Independenței 21',
    lat: 44.935230434172816,
    lng: 26.027888572454046,
    email: 'prahova@crucearosie.ro',
  },
  {
    pk: 134,
    county: 'CĂLĂRAȘI',
    locality: 'Călărași',
    address: 'Str. Zavoiului, nr. 9',
    lat: 44.20637034903384,
    lng: 27.310500296093657,
    email: 'calarasi@crucearosie.ro',
    phoneNumber: '0727688423',
  },
  {
    pk: 143,
    county: 'BIHOR',
    locality: 'Oradea',
    address: 'Bd. Dacia, nr. 23',
    lat: 47.070520041871795,
    lng: 21.923150682998944,
    email: 'bihor@crucearosie.ro',
    phoneNumber: '0761060068',
  },
  {
    pk: 144,
    county: 'BIHOR',
    locality: 'Oradea',
    address: 'Str. Victor Babeș, nr. 5',
    lat: 47.062161691425814,
    lng: 21.93489999252171,
    email: 'bihor@crucearosie.ro',
    phoneNumber: '0761060068',
  },
  {
    pk: 146,
    county: 'BIHOR',
    locality: 'Comuna Abram',
    address: 'Sat ABRAM, nr. 35',
    lat: 47.3476346,
    lng: 22.3775185,
    email: 'primaria.abram@cjbihor.ro',
    phoneNumber: '0771231144',
  },
  {
    pk: 147,
    county: 'BIHOR',
    locality: 'Comuna Abrămuț',
    address: 'Sat Abrămuț, nr. 57',
    lat: 47.3359902,
    lng: 22.2866689,
    email: 'primaria.abramut@cjbihor.ro',
    phoneNumber: '0744559945',
  },
  {
    pk: 148,
    county: 'BIHOR',
    locality: 'Aleșd',
    address: 'Aleșd',
    lat: 47.1470312,
    lng: 22.4904602,
    email: 'primaria.alesd@cjbihor.ro',
    phoneNumber: '0742598349',
  },
  {
    pk: 149,
    county: 'BIHOR',
    locality: 'Comuna Aștileu',
    address: 'Sat Aștileu',
    lat: 47.0171114,
    lng: 22.3888368,
    email: 'primaria.astileu@cjbihor.ro',
    phoneNumber: '0751281121',
  },
  {
    pk: 150,
    county: 'BIHOR',
    locality: 'Comuna Aușeu',
    address: 'Sat Aușeu',
    lat: 47.0337885,
    lng: 22.488394,
    email: 'primaria.auseu@cjbihor.ro',
    phoneNumber: '0259447036',
  },
  {
    pk: 151,
    county: 'BIHOR',
    locality: 'Avram Iancu',
    address: 'Avram Iancu',
    lat: 46.869193,
    lng: 21.9549836,
    email: 'primaria.avramiancu@cjbihor.ro',
    phoneNumber: '0771022512',
  },
  {
    pk: 152,
    county: 'BIHOR',
    locality: 'Comuna Balc',
    address: 'Sat Balc',
    lat: 47.4049738,
    lng: 22.4245793,
    email: 'primaria.balc@cjbihor.ro',
    phoneNumber: '0755755986',
  },
  {
    pk: 153,
    county: 'BIHOR',
    locality: 'Comuna Batăr',
    address: 'Sat Batăr',
    lat: 46.7043436,
    lng: 21.9452053,
    email: 'primariabatar@yahoo.com',
    phoneNumber: '0723559229',
  },
  {
    pk: 154,
    county: 'BIHOR',
    locality: 'Beiuș',
    address: 'Beiuș',
    lat: 47.0353066,
    lng: 22.3670895,
    email: 'primaria.beius@cjbihor.ro',
    phoneNumber: '0733999318',
  },
  {
    pk: 155,
    county: 'BIHOR',
    locality: 'Comuna Biharia',
    address: 'Sat Biharia',
    lat: 47.1554774,
    lng: 21.8996357,
    email: 'secretariat@comunabiharia.ro',
    phoneNumber: '0744362359',
  },
  {
    pk: 156,
    county: 'BIHOR',
    locality: 'Comuna Boianu Mare',
    address: 'Sat Boianu Mare',
    lat: 47.3895803,
    lng: 22.4817618,
    email: 'primaria.boianumare@cjbihor.ro',
    phoneNumber: '0768100404',
  },
  {
    pk: 157,
    county: 'BIHOR',
    locality: 'Comuna Borod',
    address: 'Sat Borod',
    lat: 47.018247,
    lng: 22.5971051,
    email: 'primaria_borod@yahoo.com',
    phoneNumber: '0745381102',
  },
  {
    pk: 158,
    county: 'BIHOR',
    locality: 'Comuna Borș',
    address: 'Sat Borș',
    lat: 47.1154476,
    lng: 21.8086567,
    email: 'primariabors@gmail.com',
    phoneNumber: '0741705785',
  },
  {
    pk: 159,
    county: 'BIHOR',
    locality: 'Comuna Bratca',
    address: 'Sat Bratca',
    lat: 46.927144286623275,
    lng: 22.600166797627654,
    email: 'primariabratca@yahoo.com',
    phoneNumber: '0744531187',
  },
  {
    pk: 160,
    county: 'BIHOR',
    locality: 'Comuna Brusturi',
    address: 'Sat Brusturi',
    lat: 47.1577233,
    lng: 22.2361486,
    email: 'primaria.brusturi@cjbihor.ro',
    phoneNumber: '0785255042',
  },
  {
    pk: 161,
    county: 'BIHOR',
    locality: 'Comuna Budureasa',
    address: 'Sat Budureasa',
    lat: 46.7197213,
    lng: 22.4924994,
    email: 'primaria.budureasa@cjbihor.ro',
    phoneNumber: '0762692755',
  },
  {
    pk: 162,
    county: 'BIHOR',
    locality: 'Comuna Buduslău',
    address: 'Sat Buduslău',
    lat: 47.4887214,
    lng: 22.2388336,
    email: 'primaria.buduslau@cjbihor.ro',
    phoneNumber: '0771293475',
  },
  {
    pk: 163,
    county: 'BIHOR',
    locality: 'Comuna Bulz',
    address: 'Sat Bulz',
    lat: 47.0158319,
    lng: 22.7723472,
    email: 'primaria.bulz@cjbihor.ro',
    phoneNumber: '0733927515',
  },
  {
    pk: 164,
    county: 'BIHOR',
    locality: 'Comuna Buntești',
    address: 'Sat Buntești',
    lat: 46.612432,
    lng: 22.4625238,
    email: 'primaria.buntesti@cjbihor.ro',
    phoneNumber: '0729996662',
  },
  {
    pk: 165,
    county: 'BIHOR',
    locality: 'Comuna Căbești',
    address: 'Sat Căbești',
    lat: 46.9138004,
    lng: 22.5007778,
    email: 'contact@comunacabesti.ro',
    phoneNumber: '0728825231',
  },
  {
    pk: 166,
    county: 'BIHOR',
    locality: 'Comuna Căpâlna',
    address: 'Sat Căpâlna',
    lat: 46.7353924,
    lng: 22.099805,
    email: 'primaria.capilna@cjbihor.ro',
    phoneNumber: '0745508730',
  },
  {
    pk: 167,
    county: 'BIHOR',
    locality: 'Comuna Cărpinat',
    address: 'Sat Cărpinat',
    lat: 46.4547352,
    lng: 22.4952152,
    email: 'primaria.carpinet@cjbihor.ro',
    phoneNumber: '0771429096',
  },
  {
    pk: 168,
    county: 'BIHOR',
    locality: 'Comuna Câmpani',
    address: 'Sat Câmpani',
    lat: 46.7109017,
    lng: 22.7329232,
    email: 'primariacimpani@yahoo.com',
    phoneNumber: '0742248494',
  },
  {
    pk: 169,
    county: 'BIHOR',
    locality: 'Comuna Cefa',
    address: 'Sat Cefa',
    lat: 46.9489477,
    lng: 21.772981,
    email: 'primaria.cefa@cjbihor.ro',
    phoneNumber: '0731221269',
  },
  {
    pk: 170,
    county: 'BIHOR',
    locality: 'Comuna Ceica',
    address: 'Sat Ceica',
    lat: 46.8808236,
    lng: 22.3090074,
    email: 'primaria.ceica@cjbihor.ro',
    phoneNumber: '0771145190',
  },
  {
    pk: 171,
    county: 'BIHOR',
    locality: 'Comuna Cetariu',
    address: 'Sat Cetariu',
    lat: 47.2001419,
    lng: 22.1520159,
    email: 'primaria.cetariu@cjbihor.ro',
    phoneNumber: '0745432405',
  },
  {
    pk: 172,
    county: 'BIHOR',
    locality: 'Comuna Cherechiu',
    address: 'Sat Cherechiu',
    lat: 47.3759245,
    lng: 22.1330112,
    email: 'registratura@cherechiu.ro',
    phoneNumber: '0774628165',
  },
  {
    pk: 219,
    county: 'BIHOR',
    locality: 'Comuna Sâmbăta',
    address: 'Sat Sâmbăta',
    lat: 46.6950857,
    lng: 21.5806887,
    email: 'primaria_sambata@yahoo.com',
    phoneNumber: '0732135163',
  },
  {
    pk: 220,
    county: 'BIHOR',
    locality: 'Comuna Sânicolau Român',
    address: 'Sat Sânicolau Român',
    lat: 46.9613036,
    lng: 21.7152113,
    email: 'primaria.sanicolauroman@cjbihor.ro',
    phoneNumber: '0740158026',
  },
  {
    pk: 221,
    county: 'BIHOR',
    locality: 'Comuna Sâniob',
    address: 'Sat Sâniob',
    lat: 47.26238042594605,
    lng: 22.12848203589908,
    email: 'registratura@saniob.ro',
    phoneNumber: '0745377244',
  },
  {
    pk: 222,
    county: 'BIHOR',
    locality: 'Comuna Sânmartin',
    address: 'Sat Sânmartin',
    lat: 46.9932679,
    lng: 21.9245199,
    email: 'primaria.sanmartin@cjbihor.ro',
    phoneNumber: '0749212588',
  },
  {
    pk: 223,
    county: 'BIHOR',
    locality: 'Comuna Sântandrei',
    address: 'Sat Sântandrei',
    lat: 47.0666528,
    lng: 21.8520025,
    email: 'contact@primariasantandrei.ro',
    phoneNumber: '0754930885',
  },
  {
    pk: 224,
    county: 'BIHOR',
    locality: 'Comuna Sârbi',
    address: 'Sat Sârbi',
    lat: 47.1864273,
    lng: 22.1103909,
    email: 'registratura@comunasarbi.ro',
    phoneNumber: '0748764621',
  },
  {
    pk: 225,
    county: 'BIHOR',
    locality: 'Comuna Săcădat',
    address: 'Sat Săcădat',
    lat: 47.0420428,
    lng: 22.1393136,
    email: 'primaria.sacadat@cjbihor.ro',
    phoneNumber: '0742952297',
  },
  {
    pk: 226,
    county: 'BIHOR',
    locality: 'Comuna Spinuș',
    address: 'Sat Spinuș',
    lat: 47.200581850151295,
    lng: 22.19030973577296,
    email: 'primariaspinus@yahoo.com',
    phoneNumber: '0740760029',
  },
  {
    pk: 227,
    county: 'BIHOR',
    locality: 'Comuna Suplacu de Barcău',
    address: 'Sat Suplacu de Barcău',
    lat: 47.2598904,
    lng: 22.5243102,
    email: 'primaria.suplacudebarcau@cjbihor.ro',
    phoneNumber: '0722267322',
  },
  {
    pk: 229,
    county: 'BIHOR',
    locality: 'Comuna Șinteu',
    address: 'Sat Șinteu',
    lat: 47.1423953,
    lng: 22.515077,
    email: 'primaria.sinteu@cjbihor.ro',
    phoneNumber: '0727860333',
  },
  {
    pk: 231,
    county: 'BIHOR',
    locality: 'Comuna Ștei',
    address: 'Sat Ștei',
    lat: 46.3222497,
    lng: 22.069783,
    email: 'primaria.stei@cjbihor.ro',
    phoneNumber: '0745434565',
  },
  {
    pk: 232,
    county: 'BIHOR',
    locality: 'Comuna Șuncuiuș',
    address: 'Sat Șuncuiuș',
    lat: 46.9157492,
    lng: 22.330711,
    email: 'primaria.suncuius@cjbihor.ro',
    phoneNumber: '0762447216',
  },
  {
    pk: 233,
    county: 'BIHOR',
    locality: 'Comuna Tarcea',
    address: 'Sat Tarcea',
    lat: 47.457374,
    lng: 22.1720558,
    email: 'primaria.tarcea@cjbihor.ro',
    phoneNumber: '0741509191',
  },
  {
    pk: 234,
    county: 'BIHOR',
    locality: 'Comuna Tămășeu',
    address: 'Sat Tămășeu',
    lat: 47.2188527,
    lng: 21.8726487,
    email: 'primaria@tamaseu.ro',
    phoneNumber: '0735878380',
  },
  {
    pk: 236,
    county: 'BIHOR',
    locality: 'Comuna Tăuteu',
    address: 'Sat Tăuteu',
    lat: 47.2722625,
    lng: 22.3369638,
    email: 'primariatauteu@yahoo.com',
    phoneNumber: '0771331130',
  },
  {
    pk: 237,
    county: 'BIHOR',
    locality: 'Comuna Tileagd',
    address: 'Sat Tileagd',
    lat: 47.0712667,
    lng: 22.1967532,
    email: 'primaria_tileagd@yahoo.com',
    phoneNumber: '0760900058',
  },
  {
    pk: 238,
    county: 'BIHOR',
    locality: 'Comuna Tinca',
    address: 'Sat Tinca',
    lat: 46.7747597,
    lng: 21.92843,
    email: 'primaria.tinca@cjbihor.ro',
    phoneNumber: '0740125377',
  },
  {
    pk: 239,
    county: 'BIHOR',
    locality: 'Comuna Toboliu',
    address: 'Sat Toboliu',
    lat: 47.0331868,
    lng: 21.6983429,
    email: 'primariatoboliu@yahoo.com',
    phoneNumber: '0764996013',
  },
  {
    pk: 240,
    county: 'BIHOR',
    locality: 'Comuna Tulca',
    address: 'Sat Tulca',
    lat: 46.7869441,
    lng: 21.7723933,
    email: 'primaria.tulca@cjbihor.ro',
    phoneNumber: '0758757641',
  },
  {
    pk: 241,
    county: 'BIHOR',
    locality: 'Comuna Țețchea',
    address: 'Sat Țețchea',
    lat: 47.0444163,
    lng: 22.3194119,
    email: 'primaria.tetchea@cjbihor.ro',
    phoneNumber: '07547676353',
  },
  {
    pk: 242,
    county: 'BIHOR',
    locality: 'Comuna Uileacu de Beiuș',
    address: 'Sat Uileacu de Beiuș',
    lat: 46.685057,
    lng: 22.2185577,
    email: 'primaria.uileacudebeius@cjbihor.ro',
    phoneNumber: '0735511023',
  },
  {
    pk: 243,
    county: 'BIHOR',
    locality: 'Comuna Vadu Crișului',
    address: 'Sat Vadu Crișului',
    lat: 46.9856984,
    lng: 22.5125489,
    email: 'primaria.vaducrisului@cjbihor.ro',
    phoneNumber: '0745229897',
  },
  {
    pk: 244,
    county: 'BIHOR',
    locality: 'Comuna Valea lui Mihai',
    address: 'Sat Valea lui Mihai',
    lat: 47.5041437,
    lng: 22.1337663,
    email: 'primarie@valealuimihai.ro',
    phoneNumber: '0740673007',
  },
  {
    pk: 245,
    county: 'BIHOR',
    locality: 'Comuna Vașcău',
    address: 'Sat Vașcău',
    lat: 46.0549311,
    lng: 21.3016048,
    email: 'primaria_vascau@yahoo.com',
    phoneNumber: '0755086524',
  },
  {
    pk: 246,
    county: 'BIHOR',
    locality: 'Comuna Vârciorog',
    address: 'Sat Vârciorog',
    lat: 45.730027,
    lng: 21.1206643,
    email: 'primaria.virciorog@cjbihor.ro',
    phoneNumber: '0742214802',
  },
  {
    pk: 247,
    county: 'BIHOR',
    locality: 'Comuna Viișoara',
    address: 'Sat Viișoara',
    lat: 47.3884317,
    lng: 22.4475263,
    email: 'primaria.viisoara@cjbihor.ro',
    phoneNumber: '0744320305',
  },
  {
    pk: 249,
    county: 'ALBA',
    locality: 'Alba Iulia',
    address: 'Str. București, nr. 16',
    lat: 46.06781715402371,
    lng: 23.584173155983976,
    email: 'crucearosie.alba@gmail.com',
    phoneNumber: '0722654324',
  },
  {
    pk: 250,
    county: 'SĂLAJ',
    locality: 'Zalău',
    address: 'Str. 9 Mai, nr. R10',
    lat: 47.18361663555634,
    lng: 23.0546131001972,
    email: 'salaj@crucearosie@yahoo.com',
    phoneNumber: '0751072023',
  },
  {
    pk: 251,
    county: 'BRĂILA',
    locality: 'Brăila',
    address: 'Str. Comuna din Paris, nr. 95',
    lat: 45.282547602634786,
    lng: 27.947458413632408,
    email: 'secretariat@dasbraila.ro',
    phoneNumber: '0770639611/0729664832',
  },
  {
    pk: 253,
    county: 'BRĂILA',
    locality: 'Bordei Verde',
    address: 'Str. Principală, nr. 35',
    lat: 45.06685940235825,
    lng: 27.56745692632053,
    email: 'scbordeiverde@yahoo.com',
    phoneNumber: '0723300450',
  },
  {
    pk: 266,
    county: 'COVASNA',
    locality: 'Sfântu Gheorghe',
    address: 'Str. Kós Károly, nr. 7',
    lat: 45.86122193868186,
    lng: 25.78543626947022,
    email: 'office@crucearosie-cv.org',
    phoneNumber: '0742081202',
  },
  {
    pk: 268,
    county: 'NEAMȚ',
    locality: 'Piatra Neamț',
    address: 'Str. Ștefan cel Mare, nr. 6-8',
    lat: 46.933804758622706,
    lng: 26.368752562110345,
    email: 'bmsuppn@gmail.com',
    phoneNumber: '0748146751',
  },
  {
    pk: 269,
    county: 'NEAMȚ',
    locality: 'Roman',
    address: 'Piața Roman Vodă, nr. 1',
    lat: 46.926120276910034,
    lng: 26.929592440666397,
    email: 'primaria@primariaroman.ro',
    phoneNumber: '0745844219',
  },
  {
    pk: 270,
    county: 'NEAMȚ',
    locality: 'Târgu Neamț',
    address: 'Bd. Ștefan cel Mare, nr. 62',
    lat: 47.2054141456579,
    lng: 26.369083956018503,
    email: 'tgnt@primariatgnt.ro',
    phoneNumber: '0752365819',
  },
  {
    pk: 378,
    county: 'BISTRIȚA-NĂSĂUD',
    locality: 'Năsăud',
    address: 'Str. Piața Unirii, nr. 15',
    lat: 47.2861391,
    lng: 24.4017055,
    email: 'pon@primarianasaud.ro',
    phoneNumber: '0775313782',
  },
  {
    pk: 389,
    county: 'BISTRIȚA-NĂSĂUD',
    locality: 'Bistrița-Bîrgăului',
    address: 'Bistrița Bîrgăului, nr. 326',
    lat: 47.2086327,
    lng: 24.7578457,
    email: 'primariabistritabirgaului@yahoo.com',
    phoneNumber: '',
  },
  {
    pk: 391,
    county: 'BISTRIȚA-NĂSĂUD',
    locality: 'Comuna Șanț',
    address: 'Sat Șanț',
    lat: 47.4384055,
    lng: 24.8874823,
    email: 'primarie@comunasant.ro',
    phoneNumber: '',
  },
  {
    pk: 392,
    county: 'BISTRIȚA-NĂSĂUD',
    locality: 'Comuna Josenii Bârgăului',
    address: 'Sat Josenii Bârgăului',
    lat: 47.2120507,
    lng: 24.6790628,
    email: 'primaria.joseniibirgaului@yahoo.ro',
    phoneNumber: '0748827187',
  },
  {
    pk: 393,
    county: 'BISTRIȚA-NĂSĂUD',
    locality: 'Comuna Ilva Mare',
    address: 'Sat Ilva Mare',
    lat: 47.3658138,
    lng: 24.8717721,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 394,
    county: 'BISTRIȚA-NĂSĂUD',
    locality: 'Comuna Lechința',
    address: 'Sat Lechința',
    lat: 46.9658212,
    lng: 24.0497431,
    email: '',
    phoneNumber: '',
  },
  {
    pk: 396,
    county: 'BISTRIȚA-NĂSĂUD',
    locality: 'Comuna Rodna',
    address: 'Sat Rodna',
    lat: 47.4150393,
    lng: 24.8063171,
    email: 'scoala_fpr@yahoo.com',
    phoneNumber: '0745554466',
  },
  {
    pk: 404,
    county: 'IAȘI',
    locality: 'Iași',
    address: 'Bd. Carol I, nr. 50',
    lat: 47.180938956753145,
    lng: 27.568413667165593,
    email: 'director@crucearosie.ro',
    phoneNumber: '',
  },
  {
    pk: 405,
    county: 'MUREȘ',
    locality: 'Târgu Mureș',
    address: 'Str. Nicolae Grigorescu, nr. 19',
    lat: 46.55521302672433,
    lng: 24.56226766081246,
    email: 'director@crucearosie.ro',
    phoneNumber: '0734449930',
  },
  {
    pk: 406,
    county: 'ARGEȘ',
    locality: 'Pitești',
    address: 'Piața Vasile Milea, nr. 1',
    lat: 44.85585,
    lng: 24.87636,
    email: 'vali.ivan64@yahoo.com',
    phoneNumber: '0748544353',
  },
  {
    pk: 407,
    county: 'ARGEȘ',
    locality: 'Pitești',
    address: 'Str. Târgu din Vale, nr. 1',
    building: 'Universitatea de Stat din Pitești',
    lat: 44.84801,
    lng: 24.89244,
    email: 'adrian.samarescu@upit.ro',
    phoneNumber: '0741242332',
  },
  {
    pk: 408,
    county: 'ARGEȘ',
    locality: 'Pitești',
    address: 'Str. Gheorghe Șincai, nr. 30',
    lat: 44.85918,
    lng: 24.88312,
    email: 'asociatia.ucs@gmail.com',
    phoneNumber: '0721598321',
  },
  {
    pk: 410,
    county: 'ARGEȘ',
    locality: 'Curtea de Argeș',
    address: 'Bd. Basarabilor, nr. 23',
    lat: 45.14814,
    lng: 24.67573,
    email: 'social.arges@yahoo.com',
    phoneNumber: '0742049518',
  },
  {
    pk: 411,
    county: 'ARGEȘ',
    locality: 'Mioveni',
    address: 'Bd. Dacia',
    lat: 44.9387,
    lng: 24.90845,
    email: 'social.arges@yahoo.com',
    phoneNumber: '0742049518',
  },
  {
    pk: 412,
    county: 'ARGEȘ',
    locality: 'Pitești',
    address: 'Str. Sfânta Vineri, nr. 44',
    lat: 44.85912,
    lng: 24.8808,
    email: 'social.arges@yahoo.com',
    phoneNumber: '0742049518',
  },
  {
    pk: 413,
    county: 'ARGEȘ',
    locality: 'Topoloveni',
    address: 'Str. Vitichești, nr. 101',
    lat: 44.853142,
    lng: 24.866083,
    email: 'social.arges@yahoo.com',
    phoneNumber: '0742049518',
  },
  {
    pk: 414,
    county: 'ARGEȘ',
    locality: 'Costelti',
    address: 'Str. Pitești, nr. 1',
    lat: 44.67438,
    lng: 24.88118,
    email: 'social.arges@yahoo.com',
    phoneNumber: '0742049518',
  },
  {
    pk: 415,
    county: 'ARGEȘ',
    locality: 'Câmpulung',
    address: 'Str. Negulici, nr. 14',
    lat: 45.26378,
    lng: 25.03934,
    email: 'social.arges@yahoo.com',
    phoneNumber: '0742049518',
  },
  {
    pk: 416,
    county: 'ARGEȘ',
    locality: 'Câmpulung',
    address: 'Str. Istrate Rizeanu, nr. 213',
    lat: 45.27156,
    lng: 25.04587,
    email: 'social.arges@yahoo.com',
    phoneNumber: '0742049518',
  },
];

export default { helpCenters };
